

$navMenuBackgroundOverflow: 20px;
$navMenuBackgroundRadius: 20px;
$navMenuPadding: 20px;
$navMenuWrapperPaddingBottom: calc(#{$navMenuBackgroundOverflow}*2);

#header-wrapper{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;
    &.nav-open{
        overflow-y: auto;
        height: 100vh;
        // header{
        //     position: absolute;
        // }
        #nav-wrapper{
            visibility: visible;
            opacity: 1;
        }
    }
}


#nav-wrapper {
    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    z-index: 20;
    background-color: tan;
    padding: 0 $page-padding; //changepadding
    visibility: hidden;
    opacity: 0;
    transition: $def-trans;
    @media (max-width: $screen-xl-max){
        top: $header-height-xl;
        padding: 0 $page-padding-xl;
    }
    @media (max-width: $screen-lg-max){
        top: $header-height-lg;
        padding: 0 $page-padding-lg;
    }
    @media (max-width: $screen-md-max){
        top: $header-height-md;
        padding: 0 $page-padding-md;
    }
    @media (max-width: $screen-sm-max){
        top: $header-height-sm;
        padding: 0 $page-padding-sm;
    }
    @media (max-width: $screen-xs-max){
        top: $header-height-xs;
        padding: 0 $page-padding-xs;
    }
    .nav-menu-container {
        display: flex;
        position: relative;
        width: 100%;
        overflow-x: hidden;
        background-color: bisque;
        &.mobile{
            ul{
                width: 100% !important;
            }
        }
        ul{
            display: block;
            position: relative;
            padding: 20px;
            margin: 0;
            list-style-type: none;
            z-index: 1;
            transition: $def-trans;
            li{
                display: flex;
                align-items: center;
                line-height: 1;
                margin-bottom: $spacer*0.5;
                
                a{
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                }
                &.faded{
                    a{
                        color: #ccc;
                    }
                }
                .nav-icon-right{
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
                &.ul-title{
                    a{
                        text-transform: uppercase;
                        font-size: 1.5rem;
                    }
                }
            }
            &.hidden{
                visibility: hidden;
                opacity: 0;
            }
            &.active{
                background-color: white;
            }
            ul{
                position: absolute;
                left: 100%;
                top: 0;
                min-height: 100%;
            }
        }
    }
}