#slider-preloader{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    visibility: visible;
    opacity: 1;
    transition: $long-trans;
    z-index: 1200;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled{
        visibility: hidden;
        opacity: 0;
    }
    .slider-preloader-logo{
        width: 150px;
        #Plate-HL{
            animation: rotate-hl 1.8s infinite linear;
            transform-origin: center;
            transform-box: fill-box;
        }
    }
}


@keyframes rotate-hl {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }