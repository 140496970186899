.nav-lang-switch-holder{
    display: flex;
    align-items: center;
    .icon{
        margin-right: $spacer*0.75;
        display: flex;
        align-items: center;
        img,svg{
            height: 16px;
        }
    }
    .nav-lang-switch{
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        li{
            margin-right: $spacer*0.5 !important;
            a{
                font-family: $main-font !important;
                font-weight: $main-font-regular !important;
                font-size: 1rem;
                color: white;
                opacity: 0.5;
                transition: $def-trans;
                text-decoration: none;
                &:hover{
                    opacity: 1;
                }
            }
            &.active{
                a{
                    font-weight: bold;
                    cursor: default;
                    opacity: 1;
                }
            }
        }
    }
}