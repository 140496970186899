html{
    height: 100%;
    padding: 0;
    margin: 0;
}

body{
    position: relative;
    display: block !important;
    height: 100%;
    padding: 0;
    margin: 0;
    color: $text-color !important;
    font-family: $main-font;
    font-weight: $main-font-regular;
    &.scroll-locked{
        overflow: hidden;
    }
}

.wrapper{
    position: relative;
    min-height: 100%;
    width: 100%;
    background: white;
    padding-top: $header-height;
    @media (max-width: $screen-xl-max){
        padding-top: $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        padding-top: $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        padding-top: $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        padding-top: $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        padding-top: $header-height-xs;
    }
}

.website-content{
    padding-bottom: 0;
}


html{
    height: 100%;
    padding: 0;
    margin: 0;
}

body{
    position: relative;
    display: block !important;
    height: 100%;
    padding: 0;
    margin: 0;
    color: $text-color !important;
    font-family: $main-font;
    font-weight: $main-font-regular;
    background-color: $main-black;
    &.scroll-locked{
        overflow: hidden;
    }
}

.wrapper{
    position: relative;
    min-height: 100%;
    width: 100%;
    background: white;
    padding-top: $header-height;
    @media (max-width: $screen-xl-max){
        padding-top: $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        padding-top: $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        padding-top: $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        padding-top: $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        padding-top: $header-height-xs;
    }
}

.website-content{
    padding-bottom: 0;
    
}
