a,
a:hover,
a:focus,
a:visited {
    color: $text-color;
    font-weight: $main-font-bold;
}

.dl{
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-weight: $main-font-regular;
    font-weight: $main-font-bold;
    transition: $def-trans;
    transform: translateY(0);
    border: none;
    background-color: none;
    background: none;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
    &:hover{
        transform: translateX(3px);
        font-weight: $main-font-regular;
    }
    &.static{
        &:hover{
            transform: translateX(0);
            text-decoration: underline;
        }
    }
    &:focus{
        outline:0;
    }
    &.inactive{
        cursor: default;
        opacity: 0.5;
        &:hover{
            transform: translateY(0);
        }
    }
}

.big-link{
    display: inline-flex;
    text-decoration: none !important;
    .link-text{
        display: block;
        border: 2px solid $main-red;
        text-decoration: none !important;
        word-break:break-all;
        padding: $spacer*0.5 $spacer*0.75;
        text-transform: uppercase;
        font-family: $main-font;
        font-weight: $main-font-regular;
        font-size: 1.1rem;
        line-height: 1;
        letter-spacing: 1px;
        transition: $def-trans;

    }
    .link-icon-container{
        position: relative;
        background-color: $main-red;
        width: 48px;
        overflow: hidden;
        .icon{
            position: absolute;
            width: 96px;
            left:-48px;
            display: flex;
            transition: $def-trans;
            .left,
            .right{
                width: 48px;
                display: flex;
                justify-content: flex-end;
                padding-right: 5px;
                padding-top: 6px;
                svg{
                    stroke: white;
                }
            }
        }
    }
    &:hover{
        &:not(.inactive){
            .link-text{
                background-color: $main-red;
                color: white;
            }
            .link-icon-container{
                .icon{
                    left: 0;
                }
            }
        }
    }
    &.white-on-black{
        .link-text{
            color: white;
        }
    }
    &.compact{
        .link-text{
            border: 1px solid $main-red;
            font-size: 0.85rem;
            text-transform: none;
            padding: $spacer*0.3 $spacer*0.5;
        }
        .link-icon-container{
            width: 30px;
            .icon{
                width: 60px;
                height: 100%;
                left:-30px;
                .left,
                .right{
                    width: 30px;
                    height: 100%;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    &.inactive{
        cursor: default;
        .link-text{
            border: 2px solid $gray-700;
    
        }
        .link-icon-container{
            background-color: $gray-700;
        }
    }
}
.white-on-black{
    .big-link{
        .link-text{
            color: white;
        }
        .link-icon-container{
            .icon{
                .left,
                .right{
                }
            }
        }
        &:hover{
            .link-icon-container{
                .icon{
                }
            }
        }
        &.white-on-black{
            .link-text{
            }
        }
    }
}