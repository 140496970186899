#home-history{
    .history-row{
        display: flex;
        padding: 0 $page-padding;
        @media (max-width: $screen-md-max){
            flex-direction: column;
        }
        .then,
        .now{
            flex: 0 0 45%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .image{
                width: 80%;
                @media (max-width: $screen-md-max){
                    width: 100%;
                    max-width: 500px;
                }
                img{
                    width: 100%;
                }
            }
            .year{
                font-family: $header-font;
                font-weight: $header-font-regular;
                color: $main-red;
                font-size: 3rem;
            }
            .title{
                font-family: $main-font;
                font-weight: $main-font-bold;
                color: white;
            }
        }
        .badge{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            flex: 0 0 10%;
            flex-direction: column;
            padding-top: $spacer*10;
            max-width: 150px;
            
            @media (max-width: $screen-md-max){
                padding-top: $spacer*4;
                padding-bottom: $spacer*4;
            }
            .image{
                width: 65%;
                @media (max-width: $screen-md-max){
                    max-width: 150px;
                }
                img{
                    width: 100%;
                }
            }
            .text{
                text-align: center;
                width: 65%;
                color: white;
            }
        }
    }
}