#mobile-nav{
    position: fixed;
    top: 0;
    right: 0;
    min-width: calc((100vw - #{$page-padding-nr}*2)/3 + #{$page-padding-nr});
    height: 100vh;
    height: 100svh;
    z-index: 900 !important;
    background: $main-red;
    visibility: hidden;
    opacity: 0;
    transition: $def-trans;
    overflow-y: auto;
    @media (max-width: $screen-xl-max){
        min-width: calc((100vw - #{$page-padding-nr-xl}*2)/3 + #{$page-padding-nr-xl});
    }
    @media (max-width: $screen-lg-max){
        min-width: calc((100vw - #{$page-padding-nr-lg}*2)/2 + #{$page-padding-nr-lg});
    }
    @media (max-width: $screen-md-max){
        min-width: calc((100vw - #{$page-padding-nr-md}*2)/1.5 + #{$page-padding-nr-md});
    }
    @media (max-width: $screen-sm-max){
        min-width: 100vw;
    }
    @media (max-width: $screen-xs-max){
        min-width: 100vw;
    }
    &.open{
        visibility: visible;
        opacity: 1;
    }
    // .overlay{
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     opacity: 0.1;
    //     img{
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         filter: grayscale(100%);
    //     }
    // }
    .mobile-nav-holder{
        display: grid;
        grid-template-rows: minmax(min-content, auto) min-content; 
        grid-template-columns: 1fr;
        min-height: 100%;
        overflow: auto;
        padding: calc(#{$header-height-tiny} + #{$spacer*8}) $page-padding-nr 0 0;
        @media (max-width: $screen-xl-max){
            padding: calc(#{$header-height-tiny-xl} + #{$spacer*5}) $page-padding-nr-xl 0 0;
        }
        @media (max-width: $screen-lg-max){
            padding: calc(#{$header-height-tiny-lg} + #{$spacer*4}) $page-padding-nr-lg 0 0;
        }
        @media (max-width: $screen-md-max){
            padding: calc(#{$header-height-tiny-md} + #{$spacer*4}) $page-padding-nr-md 0 0;
        }
        @media (max-width: $screen-sm-max){
            padding: calc(#{$header-height-tiny-sm} + #{$spacer*4}) $page-padding-nr-sm 0 0;
        }
        @media (max-width: $screen-xs-max){
            padding: calc(#{$header-height-tiny-xs} + #{$spacer*4}) $page-padding-nr-xs 0 0;
        }
        .mobile-nav-main{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            .mobile-nav-ul{
                padding: 0;
                margin: 0;
                list-style-type: none;
                text-align: right;
                li{
                    &.active{
                        a{
                            font-weight: bold;
                        }
                    }
                    a{
                        position: relative;
                        display: inline-block;
                        font-size: 1rem;
                        font-family: $main-font;
                        font-weight: $main-font-regular;
                        text-decoration: none;
                        font-size: 1.75rem;
                        line-height: 1.25;
                        color: rgba(255,255,255,0.95);
                        transform: translateX(0);
                        transition: $def-trans;
                        @media (max-width: $screen-sm-max){
                            font-size: 2rem;
                        }
                    }
                    &.active{
                        a{
                            cursor: default;
                        }
                    }
                    &:not(.active){
                        a:hover{
                            transform: translateX(5px);
                        }
                    }
                }
            }
            .mobile-nav-add{
                padding: 0;
                margin: 0;
                list-style-type: none;
                margin-top: $spacer*2;
                text-align: right;
                li{
                    a{
                        position: relative;
                        display: inline-block;
                        font-family: $main-font;
                        font-weight: $main-font-regular;
                        color: rgba(255,255,255,0.75);
                        font-size: 0.83rem;
                        text-decoration: none;
                        line-height: 1.5;
                        transform: translateX(0);
                        transition: $def-trans;
                        &:hover{
                            transform: translateX(3px);
                        }
                    }
                }
            }
        }
        .mobile-nav-info{
            padding: $spacer*2 0;
            display: flex;
            justify-content: flex-end;
            color: rgba(255,255,255,0.9);
            @media (max-width: $screen-md-max){
                padding: $spacer*2 0;
            }
            @media (max-width: $screen-sm-max){
                padding: $spacer*2 0;
            }
            @media (max-width: $screen-xs-max){
                padding: $spacer 0;
            }
            ul{
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;
                li{
                    margin-right: $spacer;
                    display: flex;
                    align-items: center;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 1rem;
                        font-family: $header-font;
                        font-weight: $header-font-regular;
                        text-decoration: none;
                        color: rgba(255,255,255,0.9);
                    }
                }
            }
        }
    }
/*
    &.tiny{
        .mobile-nav__holder{
            top: 80px;
            height: calc(100% - 180px);
        }
    }
*/

}
