.home-techno-2{
    background-color: $gray-bg;
    padding-top: $spacer*10;
    padding-bottom: $spacer*10;;
    @media (max-width: $screen-sm-max){
        padding-top: $spacer*7;
        padding-bottom: $spacer*7;
    }
    h2{
        width: 70%;
        @media (max-width: $screen-sm-max){
            width: 85%;
        }
        @media (max-width: $screen-xs-max){
            width: 100%;
        }
    }
    .image{
        padding: $spacer;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: 4px;
        max-width: 100%;
        img{
            max-width: 100%;
        }
        .image-text{
            width: 50%;
            margin-bottom: $spacer*0.5;
            font-size: 0.83rem;
        }
    }
}