#configurator{
    padding-top: $spacer*5 !important;
    padding-bottom: $spacer*5 !important;
    .conf-image-holder{
        position: relative;
        width: 100%;
        &:after{
            content: '';
            display: block;
            padding-bottom: 85%;
        }
        .conf-image{
            position: absolute;
            width: 90%;
            height: 100%;
            left: 5%;
            top: 0;
            @media (max-width: $screen-sm-max){
                width: 100%;
                left: 0;
            }
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: contain;
                visibility: hidden;
                opacity: 0;
                transition: $def-trans;
                &.active{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    h2{
        width: 70%;
    }
    .conf-nav{
        margin-top: $spacer*3;
        margin-bottom: $spacer*4;
        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            li{
                margin-right: $spacer;
                &:last-child{
                    margin-right: 0;
                }
                a{
                    display: block;
                    transform: translateY(0);
                    transition: $def-trans;
                    &:hover{
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
}