#home-highlights{
    background-color: $gray-bg;
    padding-top: $spacer*10;
    padding-bottom: $spacer*10;
    .highlighs-holder{
        .highlights{
            .hl-item{
                width: auto !important;
                position: relative;
                margin-right: $spacer;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .image{
                    position: relative;
                    margin-bottom: $spacer*0.5;
                    img{
                        height: 350px;
                        display: block;
                        box-sizing: border-box;
                        @media (max-width: $screen-lg-max){
                            height: 325px;
                        }
                        @media (max-width: $screen-md-max){
                            height: 300px;
                            max-width: 100%;
                        }
                        @media (max-width: $screen-sm-max){
                            height: 250px;
                            max-width: 100%;
                        }
                        @media (max-width: $screen-xs-max){
                            height: 200px;
                            max-width: 100%;
                        }
                    }
                    .overlay-icon{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg{
                            stroke-width: 1px !important;
                            color: white;
                            opacity: 0.85;
                            filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .35));
                        }
                    }
                    .overlay{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: rgba(0,0,0,0.35);
                        visibility: hidden;
                        opacity: 0;
                        transition: $def-trans;
                    }
                    .title{
                        position: absolute;
                        right: $spacer;
                        bottom: $spacer;
                        color: white;
                        visibility: hidden;
                        opacity: 0;
                        transition: $def-trans
                    }
                }
                .link{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @media (max-width: $screen-md-max){
                        justify-content: flex-start;
                    }
                }
                &:hover{
                    .image{
                        .overlay,
                        .title{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.slick-nav{
    padding-left: $spacer*5;
    @media (max-width: $screen-md-max){
        padding-left: 0;
    }
    display: flex;
    .slick-nav-control{
        display: block;
        margin-right: $spacer;
        transition: $def-trans;
        svg{
            stroke: $gray-500;
            transition: $def-trans;
        }
        &:hover{
            &:not(.inactive){
                svg{
                    stroke: $gray-700;
                }
            }
        }
        &.inactive{
            cursor: default;
            svg{
                stroke: $gray-300;
            }
        }
    }
    .slick-nav-left{
        &:not(.inactive){
            &:hover{
                transform: translateX(-5px) scale(1.2);
            }
        }
    }
    .slick-nav-right{
        &:hover{
            &:not(.inactive){
                &:hover{
                    transform: translateX(5px) scale(1.2);
                }
            }
        }
    }
}