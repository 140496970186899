#top-image{
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .content{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        h1{
            // color: $main-red;

            // font-weight: $header-font-bold;
            //max-width: 50%;
        }
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 100%);
        z-index: 0;
    }
    &.bright{
        .overlay{
            background-color: rgba(0,0,0,0);
        }
    }
}