.project{
    &__title{
        h1{
            max-width: 70%;
        }
    }
    &__category-title{
        span{
            display: inline-block;
            background-color: $main-color;
            font-family: $header-font;
            font-weight: $header-font-bold;
            color: white;
            font-size: 0.733rem;
            text-transform: uppercase;
            padding: $spacer*0.25 $spacer*0.5;
            border-radius: 6px;
            margin-bottom: 1.38rem;
        }
    }
    &__description{
        p{
            margin-top: 0;
            max-width: 70%;
        }
    }

    &__gallery-holder{
        width: 100% !important;
        overflow-x: hidden;
        padding-left: $page-padding;
        @media (max-width: $screen-xl-max){
            padding-left: $page-padding-xl;
        }
        @media (max-width: $screen-lg-max){
            padding-left: $page-padding-lg;
        }
        @media (max-width: $screen-md-max){
            padding-left: $page-padding-md;
        }
        @media (max-width: $screen-sm-max){
            padding-left: $page-padding-sm;
        }
        @media (max-width: $screen-xs-max){
            padding-left: $page-padding-xs;
        }
        .item-gallery{
            display: flex;
            .item{
                margin-right: $spacer*1.5;
            }
        }
    
    }


    &__gallery-info{
        .gallery-dots{
            .slick-dots{
                width: auto;
                position: relative;
                bottom: auto;
                text-align: left;
                display: flex;
                align-items: center;
                li{
                    margin: 0;
                    button{
                        &:before{
                            color: $gray-800;
                            opacity: 1;
                        }
                    }
                    &.slick-active{
                        button{
                            &:before{
                                transform: translateY(1px);
                                font-size: 12px;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .add-info{
            width: 100%;
            font-size: 0.83rem;
            color: $gray-600;
            text-align: right;
        }
    }

    &__options{
        font-size: 1.5rem;
        .options-holder{
            border-top: 2px solid $gray-400;
            .option{
                border-bottom: 2px solid $gray-400;
                padding-top: $spacer*2;
                padding-bottom: $spacer;
            }
            .title{
                font-family: $header-font;
            }
            .value{
                font-family: $header-font;
                font-weight: 600;
            }
        }
    }
}







