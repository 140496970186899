.fade-up{
    transition: $long-trans;
    transform: translateY(30px);
    filter: blur(3px);
    opacity: 0;
    &.done{
        transform: translateY(0);
        filter: blur(0);
        opacity: 1;
    }
}