.fs-slider{
    width: 100%;
    height: 100vh;
    height: 100svh;
    position: relative;
    z-index: 500;
    .fs-slider__slides.hidden{
        opacity: 0;
        visibility: hidden;
    }
    &.white-on-black{
        .fs-slider__dots{
            li{
                a{
                    background-color: white !important;
                }
            }
        }
        .slide-volume{
            color: white;
            span{
                svg{
                    stroke: white;
                }
            }
        }
    }
    .fs-slider__slides{
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
        transition: $long-trans;
        .slide{
            visibility: hidden;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: row;
            transition: $long-trans;
            overflow: hidden;
            &.white-on-black{
                .slide__content{
                    color: white;
                    .slide__content-info{
                        .slide__content-header{
                            color: white;
                        }
                    }
                }
            }
            &__overlay{
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0);
            }
            &__img{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;
            }
            &__content{
                width: 100%;
                height: 100%;
                max-width: inherit;
                bottom: 0;
                transition: $def-trans;
                bottom: $spacer*2;
                z-index: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                padding-bottom: $spacer*3;
                .slide__content-info{
                    width: 50%;
                    
                    @media (max-width: $screen-xl-max){
                        width: 50%;
                    }
                    @media (max-width: $screen-lg-max){
                        width: 75%;
                    }
                    @media (max-width: $screen-md-max){
                        width: 75%;
                    }
                    @media (max-width: $screen-sm-max){
                        width: 100%;
                    }
                    @media (max-width: $screen-xs-max){
                        width: 100%;
                    }
                    @media (orientation: landscape) and (max-width: $screen-md-max){
                        width: 100%;
                    }
                    margin-bottom: $spacer;
                    .slide__content-header,
                    .slide__content-secheader{
                        font-size: 4rem;
                        font-family: $header-font;
                        text-transform: uppercase;
                        font-weight: $header-font-regular;
                        color: $main-red;
                        line-height: 1;
                        
                        @media (max-width: $screen-sm-max){
                            font-size: 3rem;
                        }
                        @media (orientation: landscape) and (max-width: $screen-md-max){
                            font-size: 3rem;
                        }
                    }
                    .slide__content-header{
                        color: $text-color;
                    }
                    .slide__content-description{
                        font-size: 1rem;
                        margin-top: $spacer*2;
                        @media (max-width: $screen-sm-max){
                            margin-top: $spacer;
                        }
                        @media (orientation: landscape) and (max-width: $screen-md-max){
                            margin-top: $spacer;
                        }
                    }
                }
                .slide__content-link{
                    width: 50%;
                    margin-top: $spacer;
                    @media (max-width: $screen-xl-max){
                        width: 50%;
                    }
                    @media (max-width: $screen-lg-max){
                        width: 75%;
                    }
                    @media (max-width: $screen-md-max){
                        width: 75%;
                    }
                    @media (max-width: $screen-sm-max){
                        width: 100%;
                    }
                    @media (max-width: $screen-xs-max){
                        width: 100%;
                        margin-top: $spacer*0.5;
                    }
                    @media (orientation: landscape) and (max-width: $screen-md-max){
                        width: 100%;
                        margin-top: $spacer*0.5;
                    }
                    a{
                        
                    }
                }
            }
            video{
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-size: cover;
            }
        }
        .slide.active{
            visibility: visible;
            opacity: 1;
            .slide__text,
            .slide__link{
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .fs-slider__nav{
        position: absolute;
        left: $spacer;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - #{$spacer*2});
        display: flex;
        z-index: 2;
        visibility: hidden;
        opacity: 0;
        display: flex;
        justify-content: space-between;
        @media (orientation: landscape) and (max-width: $screen-sm-max){
            display: none !important;
        }
        @media (max-width: $screen-xs-max){
            display: none !important;
        }
        &.isvis{
            visibility: visible;
            opacity: 1;
        }
        .slide-nav{
            display: block;
            position: relative;
            margin-right: $spacer*3;
            padding: calc(#{$spacer} / 2) 0;
            transition: $def-trans;
            i,svg{
                font-size: 2rem;
                color: rgba(255,255,255,0.5);
                transition: $def-trans;
            }
            &:last-child{
                margin-right: 0;
                &:after{
                    display: none;
                }
            }
            &:hover{
                &.prev{
                    i,svg{
                        transform: translateX(-3px);
                    }
                }
                &.next{
                    i,svg{
                        transform: translateX(3px);
                    }
                }
                i,svg{
                    color: rgba(255,255,255,0.9);
                }
            }
        }
    }
    .fs-slider__dots{
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        right: $spacer*2;
        bottom: $spacer*2.5;
        display: flex;
        visibility: hidden;
        opacity: 0;
        
        @media (max-width: $screen-sm-max){
            display: none !important;
        }
        
        @media (orientation: landscape) and (max-width: $screen-md-max){
            display: none !important;
        }
        &.isvis{
            visibility: visible;
            opacity: 1;
        }
        li{
            margin-right: $spacer*0.75;
            display: flex;
            align-items: center;
            &:last-child{
                margin-right: 0;
            }
            a{
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 20px;
                background-color: $main-black;
                transition: $def-trans;
            }
            &.active{
                a{
                    cursor: default;
                    width: 10px;
                    height: 10px;
                }
            }
            &:not(.active){
                a{
                    &:hover{
                        background-color: $main-red;
                    }
                }
            }
        }
    }
        
    .slide-volume{
        position: absolute;
        color: $main-black;
        width: 35px;
        height: 35px;
        left: $spacer*2;
        bottom: $spacer*2.5;
        z-index: 1100;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        @media (max-width: $screen-sm-max){
            left: auto;
            right: $spacer*2;
        }
        @media (orientation: landscape) and (max-width: $screen-md-max){
            left: auto;
            right: $spacer*2;
        }
        &.enabled{
            visibility: visible !important;
            opacity: 1 !important;
        }
        span{
            width: 24px;
            height: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: $def-trans;
            svg{
                transition: $def-trans;
                stroke: $main-black;
            }
        }
        .disable-sound{
            visibility: hidden;
            opacity: 0;
        }
        .enable-sound{
            visibility: hidden;
            opacity: 0;
        }
        &.muted{
            .sound{
                visibility: hidden;
                opacity: 0;
            }
            .no-sound{
                visibility: visible;
                opacity: 1;
            }
            &:hover{
                .no-sound{
                    visibility: hidden;
                    opacity: 0;
                }
                .enable-sound{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        &:not(.muted){
            .sound{
                visibility: visible;
                opacity: 1;
            }
            .no-sound{
                visibility: hidden;
                opacity: 0;
            }
            &:hover{
                .sound{
                    visibility: hidden;
                    opacity: 0;
                }
                .disable-sound{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

