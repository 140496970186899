$spacer: 1rem;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$def-trans: all 200ms ease-out;
$long-trans: all 750ms ease-out;

$text-color: rgba(55,55,55,1);
$gray-text-on-black: rgba(130,130,130,1);
$main-black: #101011;
$main-color: #999999;
$main-red: #BB1929;
$gray-bg: #f5f5f5;
$accent-color: $main-red;
$dark-accent-color: $main-red;
$alert-color: $main-red;
$screen-xxs-max: 449px;
$screen-xs-min: 450px;
$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;
$screen-xl-min: 1200px;
$screen-xl-max: 1499px;
$screen-xxl-min: 1500px;
$screen-xxxl-min: 2300px;

$main-font: "urw-form", sans-serif;
$header-font: "novecento-sans-narrow", sans-serif;

$main-font-regular: 400;
$main-font-bold: 600;
$header-font-regular: 300;
$header-font-bold: 800;

$header-height: 80px;
$header-height-xl: 80px;
$header-height-lg: 75px;
$header-height-md: 70px;
$header-height-sm: 65px;
$header-height-xs: 65px;

$header-height-tiny: 60px;
$header-height-tiny-xl: 60px;
$header-height-tiny-lg: 65px;
$header-height-tiny-md: 55px;
$header-height-tiny-sm: 40px;
$header-height-tiny-xs: 40px;

$page-padding: $spacer*5;
$page-padding-xl: $spacer*5;
$page-padding-lg: $spacer*4;
$page-padding-md: $spacer*3;
$page-padding-sm: $spacer*2;
$page-padding-xs: $spacer;

$page-padding-nr: $spacer*15;
$page-padding-nr-xl: $spacer*12;
$page-padding-nr-lg: $spacer*10;
$page-padding-nr-md: $spacer*5;
$page-padding-nr-sm: $spacer*3;
$page-padding-nr-xs: $spacer*2;

$max-width: 2300px;
$border-radius: 0;