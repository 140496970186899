
.section-container{
    margin-bottom: $spacer*10 !important;
    @media (max-width: $screen-xl-max){
        margin-bottom: $spacer*9 !important;
    }
    @media (max-width: $screen-lg-max){
        margin-bottom: $spacer*8 !important;
    }
    @media (max-width: $screen-md-max){
        margin-bottom: $spacer*7 !important;
    }
    @media (max-width: $screen-sm-max){
        margin-bottom: $spacer*7 !important;
    }
    &:last-child{
        margin-bottom: 0;
    }
    .section{
        display: grid;
        column-gap: $spacer*2;
        //row-gap: $spacer*2;
        grid-template-rows: min-content min-content min-content; 
        grid-template-columns: 1fr;
        grid-template-areas: 
            "image"
            "title"
            "content";
        &:last-child{
            margin-bottom: 0;
        }

        &.lr{
            grid-template-rows: min-content min-content;
            grid-template-columns: 0.5fr 0.5fr;
            grid-template-areas: 
                "title ."
                "content image";

            @media (max-width: $screen-md-max){
                grid-template-columns: 1fr;
                grid-template-areas: 
                    "image"
                    "title"
                    "content";
            }
        }
        &.rl{
            grid-template-rows: min-content min-content;
            grid-template-columns: 0.5fr 0.5fr;
            grid-template-areas: 
                ". title"
                "image content";

            @media (max-width: $screen-md-max){
                grid-template-columns: 1fr;
                grid-template-areas: 
                    "image"
                    "title"
                    "content";
            }
        }
        .image{
            grid-area: image;
            margin-bottom: $spacer*2;
            img{
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }
        .image-text{
            color: rgba(255,255,255,0.65);
            width: 70%;
            font-size: 0.9rem;
            padding-left: 30px;
        }
        .title{
            grid-area: title;
            margin-bottom: $spacer*2;
            h2{
                margin: 0;
            }
        }
        .content{
            grid-area: content;
            p{
                &:first-child{
                    margin-top: 0;
                }
            }
        }
    }
}




// .section-wrapper{
//     min-height: 70svh;
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
//     max-width: $max-width;
//     margin: 0 auto;
//     &.nmt{
//         margin-top: $spacer*-8 !important;
//         @media (max-width: $screen-xl-max){
//             margin-top: $spacer*-5 !important;
//         }
//         @media (max-width: $screen-lg-max){
//             margin-top: $spacer*-4 !important;
//         }
//         @media (max-width: $screen-md-max){
//             margin-top: $spacer*-3 !important;
//         }
//         @media (max-width: $screen-sm-max){
//             margin-top: $spacer*-1 !important;
//         }
//     }
// }

// .section{
//     display: grid;
//     column-gap: $spacer*5;
//     row-gap: $spacer;
//     grid-template-rows: min-content min-content min-content; 
//     grid-template-columns: 1fr;
//     grid-template-areas: 
//         "title"
//         "image"
//         "content";
//     &:last-child{
//         margin-bottom: 0;
//     }
//     &.h{
//         row-gap: $spacer*1;
//         .title{
//             color: white;
//         }
//         .image{
//             max-height: 65vh;
//         }
//         .content{
//             display: flex;
//             padding: 0 !important;
//             justify-content: flex-start;
//             .content-text{
//                 padding: 0;
//                 color: white;
//                 width: 50%;
//                 //padding-right: 10%;
//                 @media (max-width: $screen-sm-max){
//                     width: 100%;
//                     padding-right: 0;
//                 }
//                 p{
//                     color: white;
//                 }
//             }
//         }
//     }
//     &.lr{
//         width: 100%;
//         &.narrow{
//             width: 95%;
//             transform: translateX(5%);
//             @media (max-width: $screen-md-max){
//                 width: 100%;
//                 transform: translateX(0);
//             }
//         }
//         grid-template-rows: min-content min-content;
//         grid-template-columns: 1fr fit-content(60%);
//         grid-template-areas: 
//             "title ."
//             "content image";

//         @media (max-width: $screen-xl-max){
//             grid-template-columns: 0.65fr 0.35fr;
//         }
//         // @media (max-width: $screen-md-max){
//         //     grid-template-columns: 0.7fr 0.3fr;
//         // }
//         @media (max-width: $screen-md-max){
//             grid-template-columns: 1fr;
//             grid-template-areas: 
//                 "image"
//                 "title"
//                 "content";
//             .title{
//                 h2{
//                     margin-top: $spacer;
//                 }
//             }   
//         }
//         .content-text{
//             &.bg{
//                 &::before{
//                     border-radius: $border-radius 0 0 $border-radius;
//                 }
//             }
//         }
//     }
//     &.rl{
//         width: 100%;
//         &.narrow{
//             width: 95%;
//             @media (max-width: $screen-md-max){
//                 width: 100%;
//             }
//         }
//         grid-template-rows: min-content min-content;
//         grid-template-columns: fit-content(60%) 1fr;
//         grid-template-areas: 
//             ". title"
//             "image content";

//         @media (max-width: $screen-xl-max){
//             grid-template-columns: 0.35fr 0.65fr;
//         }
//         // @media (max-width: $screen-md-max){
//         //     grid-template-columns: 0.3fr 0.7fr;
//         // }
//         @media (max-width: $screen-md-max){
//             grid-template-columns: 1fr;
//             grid-template-areas: 
//                 "image"
//                 "title"
//                 "content";   
//             .title{
//                 h2{
//                     margin-top: $spacer;
//                 }
//             }
//         }
//         .content-text{
//             &.bg{
//                 &::before{
//                     border-radius: 0 $border-radius $border-radius 0;
//                 }
//             }
//         }
//     }


//     @media (max-width: $screen-md-max){
//         &.rl,&.lr{
//             .content{
//                 padding: $spacer*2 0 !important;
//                 .content-text{
//                     position: relative;
//                     z-index: 0 !important;
//                     padding: 0 !important;
//                     &.bg{
//                         padding: $spacer*2 !important;
//                         &:before{
//                             content: '' !important;
//                             position: absolute !important;
//                             height: 100% !important;
//                             width: 100% !important;
//                             top: 0 !important;
//                             left: 0 !important;
//                             background-color: $gray-200 !important;
//                             z-index: -1 !important;
//                             border-radius: $border-radius !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &.rl,&.lr{
//         row-gap: 0 !important;
//         @media (max-width: $screen-xl-max){
//             row-gap: $spacer*2 !important;
//         }
//         .content{
//             grid-area: content;
//             height: auto;
//             display: flex;
//             flex-direction: column;
//             justify-content: center;
//             .content-text{
//                 position: relative;
//                 z-index: 0;
//                 padding: $spacer*4 0;
//                 &.bg{
//                     &:before{
//                         content: '';
//                         position: absolute;
//                         height: 100%;
//                         width: calc(100% + #{$spacer*10});
//                         top: 0;
//                         left: $spacer*-5;
//                         background-color: $gray-200;
//                         z-index: -1;
//                     }
//                 }
//                 p{
//                     font-size: 1rem;
//                     font-weight: $main-font-regular;
//                     &:first-child{
//                         margin-top: 0;
//                     }
//                 }

//             }
//         }
        
        
//     }
//     .image{
//         grid-area: image;
//         border-radius: $border-radius;
//         overflow: hidden;
//         img{
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             max-width: 100%;
//         }
//     }
//     .title{
//         grid-area: title;
//         h2{
//             margin: 0;
//         }
//     }
//     .content{
//         grid-area: content;
//         p{
//             &:first-child{
//                 margin-top: 0;
//             }
//         }
//     }
// }








.section-wrapper.imbg{
    padding: $spacer*5 $page-padding-nr ;
    background-position: 50% 100%;
    background-size: cover;
    position: relative;
    @media (max-width: $screen-xl-max){
        padding: $spacer*5  $page-padding-nr-xl;
    }
    @media (max-width: $screen-lg-max){
        padding: $spacer*4 $page-padding-nr-lg;
    }
    @media (max-width: $screen-md-max){
        padding: $spacer*3 $page-padding-nr-md;
    }
    @media (max-width: $screen-sm-max){
        padding: $spacer*2 $page-padding-nr-sm;
    }
    @media (max-width: $screen-xs-max){
        padding: $spacer*2 $page-padding-nr-xs;
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 300px, rgba(0,0,0,1) 100%);
        @media (max-width: $screen-md-max){
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 200px, rgba(0,0,0,1) 100%);
        }
    }
    .content{
        width: 50%;
        padding-top: 300px;
        color: white;
        z-index: 1;
        position: relative;
        
        @media (max-width: $screen-md-max){
            width: 100%;
            padding-top: 200px;
        }
        h2{
            margin-bottom: $spacer*2;
            p{
                font-size: 1rem;
                font-weight: $main-font-regular;
                color: white !important;
            }
        }
    }
}