.home-techno-highlight{
    position: relative;
    height: calc(100vh - #{$header-height-tiny});
    display: flex;
    align-items: flex-end;
    h2{
        width: 70%;
        @media (max-width: $screen-sm-max){
            width: 85%;
        }
        @media (max-width: $screen-xs-max){
            width: 100%;
        }
    }
    .video{
        position: absolute;
        width: 100%;
        height: 100%;
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }   
    }
}