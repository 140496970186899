#menu-icon-wrapper{
    .menu-icon-holder{
        display: flex;
        align-items: center;
        .nav-title{
            font-size: 0.83rem;
            margin-right: $spacer*0.5;
            color: $text-color;
            transition: $def-trans;
        }
        .menu-icon{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 30px;
            width: 40px;
            transform: translateY(2px);
            span{
                display: block;
                width: 40px;
                height: 2px;
                background-color: black;
                transition: $def-trans;
            }
            .top-bar{
                transform: translateY(-4px);
            }
            .bottom-bar{
                transform: translateY(4px);
            }
            &.open{
                .top-bar{
                    transform: translateY(1px) rotate(45deg);
                }
                .bottom-bar{
                    transform: translateY(-1px) rotate(-45deg);
                }
                &:hover{
                    .top-bar{
                        width: 36px;
                        transform: translateY(1px) translateX(2px) rotate(45deg);
                    }
                    .bottom-bar{
                        width: 36px;
                        transform: translateY(-1px) translateX(2px) rotate(-45deg);
                    }
                }
            }
            &:not(.open){
                &:hover{
                    .top-bar{
                        width: 37px;
                        transform: translateY(-4px) translateX(3px);
                    }
                    .bottom-bar{
                        width: 35px;
                    }
                }
            }
        }
    }
}

.white-on-black,
.force-white-on-black,
.mnav-open{
    #menu-icon-wrapper{
        .menu-icon-holder{
            .nav-title{
                color: white;
            }
            .menu-icon{
                span{
                    background-color: white;
                }
            }
        }
    }
}

