header{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10000;
    height: $header-height;
    transition: $def-trans;
    @media (max-width: $screen-xl-max){
        height: $header-height-xl;
    }
    @media (max-width: $screen-lg-max){
        height: $header-height-lg;
    }
    @media (max-width: $screen-md-max){
        height: $header-height-md;
    }
    @media (max-width: $screen-sm-max){
        height: $header-height-sm;
    }
    @media (max-width: $screen-xs-max){
        height: $header-height-xs;
    }
    &.sticky{
        position: sticky;
        top: 0;
    }
    &.tiny{
        height: $header-height-tiny;
        background-color: black;
        @media (max-width: $screen-xl-max){
            height: $header-height-tiny-xl;
        }
        @media (max-width: $screen-lg-max){
            height: $header-height-tiny-lg;
        }
        @media (max-width: $screen-md-max){
            height: $header-height-tiny-md;
        }
        @media (max-width: $screen-sm-max){
            height: $header-height-tiny-sm;
        }
        @media (max-width: $screen-xs-max){
            height: $header-height-tiny-xs;
        }
        #logo-holder{
            height: 56%;
        }
    }
    .header-cell{
        white-space: nowrap;
        font-size: 0.83rem;
        display: flex;
        align-items: center;
        &.header-link{
            a{
                display: inline-block;
                transform: translateY(-2px);
                text-decoration: none !important;
                font-weight: $main-font-regular;
            }
        }
    }
    #logo-holder{
        display: block;
        height: 60%;
        transition: $def-trans;
        svg{
            height: 100%;
            #the_new_reference_text{
                path,
                polygon{
                    transition: $def-trans;
                }
            }
            #the_new_reference_text{
                path{
                    transition: $def-trans;
                    fill: black;
                }
            }
        }
    }
    &.white-on-black,
    &.force-white-on-black{
        .header-cell{
            transition: $def-trans;
            color: white;
            &.header-link{
                a{
                    color: white;
                }
            }
        }
        #logo-holder{
            svg{
                #the_new_reference_text{
                    path{
                        fill: white;
                    }
                }
            }
        }
    }
    &.mnav-open{
        .header-cell{
            transition: $def-trans;
            color: white;
        }
        #logo-holder{
            @media (max-width: $screen-sm-max){
                svg{
                    #the_new_reference_text{
                        path{
                            fill: white;
                        }
                    }
                    #the_new_reference_logo{
                        path,
                        polygon{
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}
