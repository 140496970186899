
.contact-list-holder{
    
    .contact-list{
        padding: 0;
        margin: 0;
        padding-left: $spacer*3;
        list-style-type: none;
        li{
            position: relative;
            display: flex;
            align-items: center;
            font-size: 1.25rem;
            color: rgba(255,255,255,0.85);
            display: flex;
            align-items: flex-start;
            margin-bottom: $spacer;
            svg{
                stroke: rgba(255,255,255,0.5);
            }
            a{
                font-size: 1.25rem;
                color: rgba(255,255,255,0.85);
                font-weight: $main-font-regular;
                &:hover{
                    font-weight: $main-font-regular;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            .icon{
                width: 20px;
                height: 20px;
                position: absolute;
                left: -35px;
                text-align: right;
            }
            p{
                font-size: 1.25rem;
                margin: 0;
            }
        }
    }
}