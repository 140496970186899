#site-wrapper{
    position: relative;
    display: grid;
    min-height: 100vh;
    width: 100%;
    grid-template-rows: $header-height minmax(min-content, auto) min-content; 
    grid-template-columns: 1fr;
    grid-template-areas: 
        "header"
        "main"
        "footer";

    @media (max-width: $screen-xl-max) {
        grid-template-rows: $header-height-xl auto min-content; 
    }

    @media (max-width: $screen-lg-max) {
        grid-template-rows: $header-height-lg auto min-content; 
    }

    @media (max-width: $screen-md-max) {
        grid-template-rows: $header-height-md auto min-content; 
    }

    @media (max-width: $screen-sm-max) {
        grid-template-rows: $header-height-sm auto min-content; 
    }

    @media (max-width: $screen-xs-max) {
        grid-template-rows: $header-height-xs auto min-content; 
    }
    header{
        grid-area: header;
    }
    
    main{
        grid-area: main;
        width: 100%;
        overflow-x: hidden;
    }
    
    footer{
        grid-area: footer;
    }
    &.header-overlay{
        header{
            grid-row: 1 / 2;
        }
        main{
            grid-row: 1 / 3;
        }
    }
}

