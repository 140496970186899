#scroll-top{
    position: fixed;
    left: 0;
    bottom: 100px;
    width: calc(80px - #{$spacer});
    height: calc(80px - #{$spacer});
    background: $main-color;
    color: white;
    z-index: 3000;
    font-size: 2rem;
    cursor: pointer;
    transform: translateY(150px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: $def-trans;
    @media (max-width: $screen-md-max){
        font-size: 1.75rem;
    }
    @media (max-width: $screen-sm-max){
        font-size: 1.5rem;
    }
    @media (max-width: $screen-xs-max){
        font-size: 1.5rem;
    }
    &.isvis{
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        &:hover{
            transform: translateY(-5px);
        }
    }
}
