.home-greeting{
    h1{
        width:70%;
        @media (max-width: $screen-sm-max){
            width: 85%;
        }
        @media (max-width: $screen-xs-max){
            width: 100%;
        }
    }
    img{
        border: none;
        max-width: 100%;
        max-height: 700px;
        @media (max-width: $screen-md-max){
            margin-right: $page-padding-nr-md*-1 !important;
        }
        @media (max-width: $screen-sm-max){
            margin-right: $page-padding-nr-sm*-1  !important;
        }
        @media (max-width: $screen-xs-max){
            margin-right: $page-padding-nr-xs*-1 !important;
        }
    }
}